//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "AppCard",
  props: ["app"],
  methods: {
    getAppLink(id) {
      return "/app-details/" + id;
    },
    getAppDesc(desc) {
      return desc.substring(0, 75) + "..";
    }
  }
};
