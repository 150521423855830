import { render, staticRenderFns } from "./index.vue?vue&type=template&id=51bdb78e&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51bdb78e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeaturedApp: require('/Users/ravi/Projects/VSCode/caesiumstudio/components/FeaturedApp.vue').default,AppCard: require('/Users/ravi/Projects/VSCode/caesiumstudio/components/AppCard.vue').default})
