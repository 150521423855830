//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FeaturedApp from "@/components/FeaturedApp.vue";
import AppCard from "@/components/AppCard.vue";

export default {
  name: "IndexPage",
  components: {
    FeaturedApp,
    AppCard
  },
  computed: {
    apps() {
      return this.$store.state.apps;
    }
  }
};
