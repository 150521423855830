//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FeaturedApp'
}
